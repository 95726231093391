import { objectIdTypePolicy, dateTypePolicy } from '../typePolicies'

export default {
  Author: { fields: { id: objectIdTypePolicy } },
  AuthorTagObject: { fields: { id: objectIdTypePolicy } },
  BaseNotificationObject: { fields: { id: objectIdTypePolicy, time: dateTypePolicy } },
  Comment: { fields: { id: objectIdTypePolicy, parent: objectIdTypePolicy } },
  Meta: { fields: { createdAt: dateTypePolicy, modifiedAt: dateTypePolicy } },
  Mutation: { fields: { serverDate: dateTypePolicy } },
  PVSubscription: { fields: { id: objectIdTypePolicy } },
  Playlist: { fields: { id: objectIdTypePolicy } },
  PlaylistContentForVideo: { fields: { id: objectIdTypePolicy } },
  PostCommentResponse: { fields: { commentId: objectIdTypePolicy } },
  RegularTagObject: { fields: { id: objectIdTypePolicy } },
  ReplyNotificationObject: {
    fields: { cid: objectIdTypePolicy, id: objectIdTypePolicy, repliedObj: objectIdTypePolicy, time: dateTypePolicy },
  },
  Subscription: { fields: { serverDate: dateTypePolicy } },
  SystemNotificationObject: { fields: { id: objectIdTypePolicy, time: dateTypePolicy } },
  Thread: { fields: { id: objectIdTypePolicy } },
  User: { fields: { id: objectIdTypePolicy } },
  Video: { fields: { id: objectIdTypePolicy } },
  VideoItem: { fields: { uploadTime: dateTypePolicy } },
}
