
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "NotificationObject": [
      "BaseNotificationObject",
      "ReplyNotificationObject",
      "SystemNotificationObject"
    ],
    "TagObject": [
      "AuthorTagObject",
      "RegularTagObject"
    ]
  }
};
      export default result;
    